<section id="blog" class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-title text-center">
          <h1>A GREAT TECHNOLOGY</h1>
          <p>Growing together in the digital age Paramedical healthcare field attracts.</p>
        </div>
      </div>
    </div>

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">The Orthodontic landscape is changing <span class="text-muted"></span></h2>
        <p class="lead">As the industry converts to more digital means of labor, we are staying ahead of the game Some of the benefits of going digital include eliminating:</p>
        <div class="list-unstyled">
          <ul class="list-group row">
          <li class="col-xs-6">Messy impressions</li>
          <li class="col-xs-6">Patients’ discomfort</li>
            <hr>
          <li class="col-xs-6">Cost of material</li>
          <li class="col-xs-6">Extended chairside time</li>
          </ul>
        </div>
        <hr>
        <ul class="list-group row">
          <li class="fa-solid fa-flask col-xs-6"> ITero</li>
          <li class="fa-solid fa-flask col-xs-6"> EasyRx</li>
          <hr>
          <li class="fa-solid fa-flask col-xs-6"> STL’s file</li>
          <li class="fa-solid fa-flask col-xs-6"> Secure Electronic Submissions</li>
        </ul>
       <br>
        <p class="bold-text">We also provide a platform for quick and secure submissions by logging in, or you can also submit through iTero. See the form at the bottom of the home page to see how you can get started with iTero.</p>


      </div>
      <div class="col-md-5">
        <img class="featurette-image img-responsive" data-src="holder.js/500x500/auto" alt="500x500" src="assets/img/bg/5.png">
      </div>
    </div>

  </div>
</section>
