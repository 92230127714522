<footer>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="copyright">
          <p>DaalOrtho &copy; Copyright 2024. All Rights Reserved.</p>
        </div>
      </div>

      <div class="header-top">
        <div class="auto-container">
          <div class="top-inner clearfix">
            <ul class="info top-left pull-left">
              <li><i style="color: white;" class="fas fa-phone-volume"></i><a style="color: white;" href="tel:1234567890"> (804) 617-5901</a></li>
              <li><i style="color: white;" class="fas fa-envelope"></i><a style="color: white;" href="mailto:info@daalortho.com"> info"&#64;daalortho.com</a></li>
            </ul>
            <ul class="top-right pull-right">
              <li style="color: white;" class="work-time"><i class="fas fa-clock" style="color: white;"></i> Open Hours - Mon - Fri: 9:00 am - 5:00 pm</li>
            </ul>
          </div>
        </div>
      </div>


      <div class="col-xs-12 col-sm-6">
        <ul class="social-media">
          <li><a href="https://www.facebook.com/daalorthotech" target="_blank"><i class="fa fa-facebook"></i></a></li>
<!--          <li><a href="#"><i class="fa fa-twitter"></i></a></li>-->
<!--          <li><a href="#"><i class="fa fa-google-plus"></i></a></li>-->
<!--          <li><a href="#"><i class="fa fa-youtube"></i></a></li>-->
          <li><a href="https://www.instagram.com/daalortho08/" target="_blank"><i class="fa fa-instagram"></i></a></li>
        </ul>
      </div>

    </div>
  </div>
</footer>
