import { Component } from '@angular/core';
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-fun-facts',
  standalone: true,
  imports: [
    NgStyle
  ],
  templateUrl: './fun-facts.component.html',
  styleUrl: './fun-facts.component.css'
})
export class FunFactsComponent {

}
