<div id="banner" class="carousel slide" data-ride="carousel">
  <!-- Wrapper for slides -->
  <div class="carousel-inner" role="listbox">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#banner" data-slide-to="0" class="active"></li>
      <li data-target="#banner" data-slide-to="1"></li>
      <li data-target="#banner" data-slide-to="2"></li>
    </ol>

    <!--1 SLIDE-->
    <div class="item active" [ngStyle]="{'background-image':'url(assets/img/banner/1.jpg)'}">
      <div class="caption-info">
        <div class="container">
          <div class="row">
            <div class="col-sm-7 col-md-5 col-lg-4">
              <div class="caption-info-inner">
                <h1 class="cd-headline clip is-full-width animated fadeInDown">
										<span class=cd-words-wrapper>
											<b class=is-visible>Welcome to DAAL Orthotech Laboratory</b>


											<b>View our Services</b>
										</span>
                </h1>
<!--                <p class="animated fadeInUp">Lorem Ipsum is simply dummy text of the printing and typeseatting industry.</p>-->
<!--                <a href="#about-us" class="animated fadeInUp btn btn-primary page-scroll">View our Services</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END 1 SLIDE-->

    <!--2 SLIDE-->
    <div class="item" [ngStyle]="{'background-image':'url(assets/img/banner/2.jpg)'}">
      <div class="caption-info">
        <div class="container">
          <div class="row">
            <div class="col-sm-7 col-md-5 col-lg-4">
              <div class="caption-info-inner">
                <h1 class="cd-headline clip is-full-width animated fadeInDown">
										<span class=cd-words-wrapper>
											<b class=is-visible>Excellent quality</b>
										</span>
                </h1>
                <p class="animated fadeInUp">Our clients can rest assured that the quality of the appliances, and technical support/assistance they receive from our lab is excellent.</p>
<!--                <a href="#about-us" class="animated fadeInUp btn btn-primary page-scroll">Read More</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END 2 SLIDE-->


    <!--3 SLIDE-->
    <div class="item" [ngStyle]="{'background-image':'url(assets/img/banner/3.jpg)'}">
      <div class="caption-info">
        <div class="container">
          <div class="row">
            <div class="col-sm-7 col-md-5 col-lg-4">
              <div class="caption-info-inner">
                <h1 class="cd-headline clip is-full-width animated fadeInDown">
										<span class=cd-words-wrapper>
											<b class=is-visible>Perfect Fit</b>
<!--											<b>We Care About Your Health</b>-->
<!--											<b>Cosmetic dental surgery</b>-->
										</span>
                </h1>
                <p class="animated fadeInUp">Daal Orthotech guarantees that our appliances will properly fit on the model. In order to provide the best service, we encourage each clinic to inspect the impressions/models for defects or distortions before sending them off for pick up or shipping. If returning a model due to a fabrication issue, we require the old model be returned as well to identify where the problem occurred.</p>
<!--                <a href="#about-us" class="animated fadeInUp btn btn-primary page-scroll">Read More</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END 3 SLIDE-->

    <!-- Previous/Next controls -->
    <a class="left carousel-control" href="#banner" role="button" data-slide="prev">
      <span class="icon-prev" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#banner" role="button" data-slide="next">
      <span class="icon-next" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>

  </div><!--end carousel-inner-->
</div>


