<section id="services" class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-title text-center">
          <h1>Explore Our Services</h1>
          <p>What We Do Now</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="service-list wow fadeIn">

          <div class="thumb">
          <video class="image-box" width="100%" height="250"  autoplay loop >
            <source  src="/assets/images/services/retainer.mp4" type="video/mp4" />
          </video>
          </div>
            <div class="service-info">
              <h3>Retainers</h3>
              <p> A retainer usually is prescribed at the completion of orthodontic treatment. Holding tooth in positions is necessary to minimize relapse while periapical fibers of the periodontal membrane and supracrestal fibers readapt and stabilize</p>
            </div>
        </div>
      </div>

      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="service-list wow fadeIn">
          <div class="thumb">
            <video class="image-box" width="100%" height="250"  autoplay loop >
              <source  src="/assets/images/services/arch.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="service-info">
            <h3>Arch Development</h3>
            <p>Arch development appliances are designed to exert force outwardly to create space for crowded teeth, new teeth, and help to widen and lengthen the arch.</p>
          </div>
        </div>
      </div>

      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="service-list wow fadeIn">
          <div class="thumb">
            <video class="image-box" width="100%" height="250"  autoplay loop >
              <source  src="/assets/images/services/removableAppliances.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="service-info">
            <h3>Removable Appliances</h3>
            <p>Removable orthodontic appliances are designed to be easily removed by the patient. These are the most common types of retainer in use today.</p>
          </div>
        </div>
      </div>

      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="service-list wow fadeIn">
          <div class="thumb">
            <video class="image-box" width="100%" height="250"  autoplay loop >
              <source  src="/assets/images/services/fixAppliances.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="service-info">
            <h3>Fixed Appliances</h3>
          </div>
        </div>
      </div>

      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="service-list wow fadeIn">
          <div class="thumb">
            <video class="image-box" width="100%" height="250"  autoplay loop >
              <source  src="/assets/images/services/digital.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="service-info">
            <h3>Digital</h3>
            <p>Digital files are sent to our lab through various different means (iTero, EasyRX, or Daalortho.com), and later we print them and deliver them to our clients. We can modify/Manipulate scans as well, just ask us.</p>
          </div>
        </div>
      </div>

      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="service-list wow fadeIn">
          <div class="thumb">
            <video class="image-box" width="100%" height="250"  autoplay loop >
              <source  src="/assets/images/services/flipper.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="service-info">
            <h3>Flippers/pontics</h3>
            <p>Flippers are used to replace missing teeth and can be made in a variety of shades to match patients' teeth. Please be sure to specify shade color and manufacturer when ordering an appliance.</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
