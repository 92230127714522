<div class="modal fade modal-vcenter" id="consultation" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h2>Request a Consultation</h2>
      </div>
      <div class="modal-body">
        <form id="reservation-form" method="post" data-toggle="validator">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label>First Name*</label>
                <input type="text" id="rfname" class="form-control" name="fname" required data-error="Please enter your first name">
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label>Last Name</label>
                <input type="text" id="rlname" class="form-control" name="lname" >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label>Email*</label>
                <input type="email" id="remail" class="form-control" name="email" required data-error="Please enter valid email address">
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label>Phone*</label>
                <input type="tel" id="rphone" class="form-control" name="phone" required data-error="Please enter your phone number">
                <div class="help-block with-errors"></div>
              </div>
            </div>

          </div>

          <div class="form-group">
            <label>Address</label>
            <input type="text" id="raddress" class="form-control" name="address">
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-4">
              <div class="form-group">
                <label>Zip Code</label>
                <input type="text" id="rzipcode" class="form-control" name="zipcode">
              </div>
            </div>

            <div class="col-xs-12 col-sm-8">
              <div class="form-group">
                <label>City</label>
                <input type="text" id="rcity" class="form-control" name="city">
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Service</label>
            <select id="rselect-service" class="select-option" name="service">
              <option value="Cleanings">Cleanings</option>
              <option value="Crowns & bridges">Crowns & bridges</option>
              <option value="Root canals">Root canals</option>
              <option value="Cosmetic dentistry">Cosmetic dentistry</option>
              <option value="Dental implants">Dental implants</option>
              <option value="Whitening">Whitening</option>
            </select>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label>Consultation Date*</label>
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <input type="date" id="rdate" class="form-control date-pic" name="date" placeholder="mm/dd/yyyy" required data-error="Please select reservation date">
                </div><!-- /.input group -->
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6">
<!--              <div class="bootstrap-timepicker">-->
              <div>
                <div class="form-group">
                  <label>Consultation Time*</label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <input type="text" id="rtime" name="time" class="form-control" required data-error="Please select reservation time">
                  </div><!-- /.input group -->
                  <div class="help-block with-errors"></div>
                </div><!-- /.form group -->
              </div>
            </div>
          </div>

          <div class="submit-block text-right">
            <a href="#" class="btn btn-default" data-dismiss="modal">Cancel</a>
            <input value="Submit" name="submit" class="btn btn-primary" type="submit">
          </div>

          <div id="msgSubmitRes" class="h3 text-center hidden"></div>

        </form>
      </div>

    </div>
  </div>
</div>
