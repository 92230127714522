import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {NgStyle} from "@angular/common";
import {NavigationComponentComponent} from "./navigation-component/navigation-component.component";
import {BannerComponentComponent} from "./banner-component/banner-component.component";
import {AboutComponent} from "./about/about.component";
import {ServicesComponent} from "./services/services.component";
import {DocumentsComponent} from "./documents/documents.component";
import {FunFactsComponent} from "./fun-facts/fun-facts.component";
import {PortfolioComponent} from "./portfolio/portfolio.component";
import {TechnologyComponent} from "./technology/technology.component";
import {TestimonialsComponent} from "./testimonials/testimonials.component";
import {ContactUsComponent} from "./contact-us/contact-us.component";
import {ModalComponent} from "./modal/modal.component";
import {FooterComponent} from "./footer/footer.component";



@Component({
  selector: 'app-root',
  standalone: true,

  imports: [
    RouterOutlet,
    NgStyle,
    NavigationComponentComponent,
    BannerComponentComponent,
    AboutComponent,
    ServicesComponent,
    DocumentsComponent,
    FunFactsComponent,
    PortfolioComponent,
    TechnologyComponent,
    TestimonialsComponent,
    ContactUsComponent,
    FooterComponent,
    ModalComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'DaalOrthoNew';
}
