<section id="testimonials" class="section-padding parallax-bg" [ngStyle]="{'background-image':'url(assets/img/bg/3.jpg)'}">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
        <div id="testimonial-carousel" class="carousel slide" data-ride="carousel" data-pause="false">
          <!-- Wrapper for slides -->
          <div class="carousel-inner" role="listbox">
            <div class="item active text-center">
              <div class="testi-author">
                <img src="assets/img/testimonials/dabney.jpeg" alt="">
              </div>
              <div class="testi-comments">
                <p>I’ve worked with this lab for over 19 year and her appliances are always delivered on time.</p>
                <p>Find it to be very dependable and very easy to work with when designing special appliances.</p>
              </div>
              <span class="name">Dr. Dabney</span>
              <span class="designation">Dabney Orthodontics</span>
            </div>

<!--            <div class="item text-center">-->
<!--              <div class="testi-author">-->
<!--                <img src="assets/img/testimonials/2.jpg" alt="">-->
<!--              </div>-->
<!--              <div class="testi-comments">-->
<!--                <p>Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat.Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat</p>-->
<!--              </div>-->
<!--              <span class="name">Jhon Smith</span>-->
<!--              <span class="designation">Social Worker</span>-->
<!--            </div>-->

<!--            <div class="item text-center">-->
<!--              <div class="testi-author">-->
<!--                <img src="assets/img/testimonials/1.jpg" alt="">-->
<!--              </div>-->
<!--              <div class="testi-comments">-->
<!--                <p>Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat.Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat</p>-->
<!--              </div>-->
<!--              <span class="name">Jhon Smith</span>-->
<!--              <span class="designation">Social Worker</span>-->
<!--            </div>-->

          </div>
          <br/> <hr>
          <ol class="carousel-indicators">
            <li data-target="#testimonial-carousel" data-slide-to="0" class="active"></li>
<!--            <li data-target="#testimonial-carousel" data-slide-to="1"></li>-->
<!--            <li data-target="#testimonial-carousel" data-slide-to="2"></li>-->
          </ol>

        </div>
      </div>
    </div>
  </div>
</section>
