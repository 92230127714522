<section id="portfolio" class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-title text-center">
          <h1>Our Portfolio</h1>
          <p>Explore Our Services</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <ul id="filter-list">
          <li class="filter" data-filter="all">ALL</li>
          <li class="filter" data-filter=".retainers">Retainers</li>
          <li class="filter" data-filter=".arch">Arch Development</li>
          <li class="filter" data-filter=".removable">Removable Appliances</li>
          <li class="filter" data-filter=".fixed">Fixed Appliances</li>
          <li class="filter" data-filter=".digital">Digital</li>
          <li class="filter" data-filter=".flipper">Flippers/Pontics</li>
        </ul><!-- @end #filter-list -->
      </div>

      <div class="col-xs-12">
        <ul class="portfolio_items">
          <li class="retainers mix">
            <div class="post_thumb">
              <img src="assets/images/retainers/1.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/retainers/1.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="arch mix">
            <div class="post_thumb">
              <img src="assets/images/arch_fixed/Lower%20Lingual%20Holding%20Arch.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/arch_fixed/Lower%20Lingual%20Holding%20Arch.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="removable mix">
            <div class="post_thumb">
              <img src="assets/images/removable/Hard%20and%20Soft%20Night%20Guard.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/removable/Hard%20and%20Soft%20Night%20Guard.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="retainers mix">
            <div class="post_thumb">
              <img src="assets/images/retainers/2.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/retainers/2.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="arch mix">
            <div class="post_thumb">
              <img src="assets/images/arch_fixed/Nance%20Appliance.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/arch_fixed/Nance%20Appliance.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="removable mix">
            <div class="post_thumb">
              <img src="assets/images/removable/Removeable%20Expander.jpg" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/removable/Removeable%20Expander.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="retainers mix">
            <div class="post_thumb">
              <img src="assets/images/retainers/3.jpg" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/retainers/3.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="arch mix">
            <div class="post_thumb">
              <img src="assets/images/arch_fixed/RPE%20Banded%20with%20Face%20Mask%20Arms.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="asseassets/images/arch_fixed/RPE%20Banded%20with%20Face%20Mask%20Arms.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="removable mix">
            <div class="post_thumb">
              <img src="assets/images/removable/Super%20Spring%20Aligner.jpg" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/removable/Super%20Spring%20Aligner.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="retainers mix">
            <div class="post_thumb">
              <img src="assets/images/retainers/4.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/retainers/4.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="arch mix">
            <div class="post_thumb">
              <img src="assets/images/arch_fixed/RPE%20banded%20with%20Tongue%20Habit%20Appliance.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/arch_fixed/RPE%20banded%20with%20Tongue%20Habit%20Appliance.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="fixed mix">
            <div class="post_thumb">
              <img src="assets/images/arch_fixed/Anterior%20Fixed%20Bite%20Plate.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/arch_fixed/Anterior%20Fixed%20Bite%20Plate.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>

        <!--RETAINERS-->
        <li class="retainers mix">
          <div class="post_thumb">
            <img src="assets/images/retainers/3.jpg" alt="" />
            <div class="portfolio-overlay">
              <div class="overlay-inner">
                <a href="assets/images/retainers/3.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
              </div>
            </div>
          </div><!--end post thumb-->
        </li>
        <li class="retainers mix">
          <div class="post_thumb">
            <img src="assets/images/retainers/5.jpg" alt="" />
            <div class="portfolio-overlay">
              <div class="overlay-inner">
                <a href="assets/images/retainers/5.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
              </div>
            </div>
          </div><!--end post thumb-->
        </li>
        <li class="retainers mix">
          <div class="post_thumb">
            <img src="assets/images/retainers/6.jpg" alt="" />
            <div class="portfolio-overlay">
              <div class="overlay-inner">
                <a href="assets/images/retainers/6.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
              </div>
            </div>
          </div><!--end post thumb-->
        </li>
        <li class="retainers mix">
          <div class="post_thumb">
            <img src="assets/images/retainers/7.png" alt="" />
            <div class="portfolio-overlay">
              <div class="overlay-inner">
                <a href="assets/images/retainers/7.png" class="fancybox"><i class="fa fa-camera"></i></a>
              </div>
            </div>
          </div><!--end post thumb-->
        </li>
        <li class="retainers mix">
          <div class="post_thumb">
            <img src="assets/images/retainers/8.jpg" alt="" />
            <div class="portfolio-overlay">
              <div class="overlay-inner">
                <a href="assets/images/retainers/8.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
              </div>
            </div>
          </div><!--end post thumb-->
        </li>
        <!-- END OF RETAINERS-->

          <!--DIGITAL-->
          <li class="digital mix">
            <div class="post_thumb">
              <img src="assets/images/digital/3d%20Models.jpg" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/digital/3d%20Models.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="digital mix">
            <div class="post_thumb">
              <img src="assets/images/digital/Dual%20Helix%20Retouched.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/digital/Dual%20Helix%20Retouched.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="digital mix">
            <div class="post_thumb">
              <img src="assets/images/digital/Hawley%20Retainer%20with%20Adam%20Clasp.jpg" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/digital/Hawley%20Retainer%20with%20Adam%20Clasp.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <!-- END OF DIGITAL-->

          <!--FLIPPERS-->
          <li class="flipper mix">
            <div class="post_thumb">
              <img src="assets/images/flippers/Flipper%20Appliance.jpg" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/flippers/Flipper%20Appliance.jpg" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="flipper mix">
            <div class="post_thumb">
              <img src="assets/images/flippers/Mini%20Screw%20Expander%20Retouched.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/flippers/Mini%20Screw%20Expander%20Retouched.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <li class="flipper mix">
            <div class="post_thumb">
              <img src="assets/images/flippers/Quad%20Helix%20retouched.png" alt="" />
              <div class="portfolio-overlay">
                <div class="overlay-inner">
                  <a href="assets/images/flippers/Quad%20Helix%20retouched.png" class="fancybox"><i class="fa fa-camera"></i></a>
                </div>
              </div>
            </div><!--end post thumb-->
          </li>
          <!-- END OF FLIPPERS-->
        </ul>
      </div>

    </div>
  </div>
</section>
