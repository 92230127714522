<section id="about-us" class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-title text-center">
          <h1>About Us</h1>
          <h2 id="bible">“21 For we are taking pains to do what is right, not only in the eyes of the Lord but also in the eyes of man. -2 Corinthians 8:21 (NIV)”</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-5">
        <div class="single-img wow fadeIn">
          <img src="../../assets/img/about-us/1.jpg" alt="">
        </div>
      </div>
      <div class="col-xs-12 col-md-7">
        <div class="text-block wow fadeIn">
          <h3>Our Story</h3>
          <p>DAAL Orthotech is committed to offering excellent orthodontic appliances. Our priority is to ensure our client’s satisfaction. We believe that the relationship between orthodontic practices and the laboratory is critical to meet our client’s individual needs by providing quality products and services. It is our mission to do everything possible to keep doctors, assistants, and patients satisfied.</p>
        </div>

        <h3>Our skills</h3>
        <div class="progress-block wow fadeIn">
          <div class="single-progressbar">
            <div class="skill-text">
              <span>Excellent quality</span>
              <span class="skill-per"></span>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
              </div>
            </div>
          </div>

          <div class="single-progressbar">
            <div class="skill-text">
              <span>Perfect Fit</span>
              <span class="skill-per"></span>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
              </div>
            </div>
          </div>

          <div class="single-progressbar">
            <div class="skill-text">
              <span>Root canals</span>
              <span class="skill-per"></span>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
              </div>
            </div>
          </div>

          <div class="single-progressbar">
            <div class="skill-text">
              <span>Quick Turn Around Time</span>
              <span class="skill-per"></span>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-valuenow="97" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>

  </div>
</section>
