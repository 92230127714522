<section id="fun-facts" class="section-padding parallax-bg" [ngStyle]="{'background-image':'url(assets/img/bg/4.png)'}">
  <div class="container">
    <div class="row">
      <div class="col-xs-6 col-sm-3">
        <div class="fun-facts-info wow fadeInUp">
          <i class="fa fa-user"></i>
          <span class="counter">50000</span><h4>Satisfied Patients+</h4>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3">
        <div class="fun-facts-info wow fadeInUp">
          <i class="fa fa-heartbeat"></i>
          <span class="counter">58000</span><h4>X appliances fabricated</h4>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3">
        <div class="fun-facts-info wow fadeInUp">
          <i class="fa fa-clock-o"></i>
          <span class="counter">25</span><h4>Years of Experience</h4>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3">
        <div class="fun-facts-info wow fadeInUp">
          <i class="fa fa-user-md"></i>
          <span class="counter">200</span><h4>Specialist Doctors</h4>
        </div>
      </div>
    </div>
  </div>
</section>
