
<!-- Navigation -->
<nav class="navbar navbar-default navbar-fixed-top">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="navbar-header page-scroll">
          <button type="button" class="navbar-toggle offcanvas-toggle pull-right" data-toggle="offcanvas" data-target="#navbar">
            <span class="sr-only">Toggle navigation</span>
            <span>
							  <span class="icon-bar"></span>
							  <span class="icon-bar"></span>
							  <span class="icon-bar"></span>
							</span>
          </button>
          <div class="logo">
            <a class="navbar-brand page-scroll" href="#"><img src="assets/logo.png" alt="Logo"></a>
          </div>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="navbar-offcanvas navbar-offcanvas-touch" id="navbar">
          <ul class="nav navbar-nav navbar-right">
            <li class="active">
              <a class="page-scroll" href="#page-top">Home</a>
            </li>
            <li>
              <a class="page-scroll" href="#about-us">About Us</a>
            </li>
            <li>
              <a class="page-scroll" href="#services">Services</a>
            </li>
            <li>
              <a class="page-scroll" href="#doctors">Documents</a>
            </li>
            <li>
              <a class="page-scroll" href="#portfolio">Portfolio</a>
            </li>
            <li>
              <a class="page-scroll" href="#blog">Digital</a>
            </li>
            <li>
              <a class="page-scroll" href="#contact-us">Contact Us</a>
            </li>
            <li class="consultation">
              <a class="page-scroll" href="https://dashboard.daalortho.com/" target="_blank" data-target="#consultation">LogIn</a>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
    </div>

  </div>
  <!-- /.container -->
</nav>
