<section id="contact-us" class="section-padding experience-section" [ngStyle]="{'background-image':'url(assets/img/background/experience-1.jpg)'}">
  <div class="auto-container">
    <div class="row clearfix">
      <div class="col-lg-6 col-md-12 col-sm-12 content-column">
        <div id="content_block_03">
          <div class="content-box">
            <div class="sec-title left light">
              <p>Experience</p>
              <h2>Lab Trusted Experts</h2>
              <h4 style="color: white">Over 20 years of experience.</h4>
              <!--              <span class="separator"></span>-->
            </div>

            <div class="progress-content">
              <div class="progress-box">
                <h5>Experienced in fixed and removable appliances</h5>
                <div class="bar">
                  <div class="bar-inner count-bar" data-percent="68%"></div>
                  <span class="count-text">68%</span>
                </div>
              </div>
              <div class="progress-box">
                <h5>We will meet and exceed your expectations at every turn</h5>
                <div class="bar">
                  <div class="bar-inner count-bar" data-percent="80%"></div>
                  <span class="count-text">80%</span>
                </div>
              </div>
              <div class="progress-box">
                <h5>Everything we do is designed to provide you with easy and fast service</h5>
                <div class="bar">
                  <div class="bar-inner count-bar" data-percent="61%"></div>
                  <span class="count-text">61%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 form-column">
        <div id="content_block_04">
          <div class="form-inner">
            <div class="sec-title left">
              <p>Become a Client</p>
              <h2>Get Started with DAAL Orthotech</h2>
              <span class="separator"></span>
            </div>
            <form action="#" class="appointment-form">
              <div class="form-group">
                <i class="fas fa-user"></i>
                <input type="text" name="name" placeholder="Doctor's Name" required="">
              </div>
              <div class="form-group">
                <i class="fas fa-envelope"></i>
                <input type="email" name="email" placeholder="Email" required="">
              </div>
              <div class="form-group">
                <i class="fas fa-file-alt"></i>
                <input type="text" name="subject" placeholder="Subject" required="">
              </div>
              <div class="form-group">
                <label for="type">Select a Reason:</label>

                <select name="type" id="type" class="form-control">
                  <option value="1">Request Price List</option>
                  <option value="2">I just have a question</option>
                  <option value="3">I am interested in using your services</option>
                </select>
              </div>
              <div class="form-group">
                <textarea name="message" placeholder="Message"></textarea>
              </div>
              <div class="form-group message-btn">
                <button type="submit" class="theme-btn style-one">Submit</button>
              </div>
              <div class="form-group">
                <div class="custom-controls-stacked">
                  <label class="custom-control material-checkbox">
                    <input type="checkbox" class="material-control-input">
                    <span class="material-control-indicator"></span>
                    <span class="description">I agree that my submitted data is being collected and stored.</span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
