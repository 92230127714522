import { Component, OnInit } from '@angular/core';
import {NgStyle} from "@angular/common";


@Component({
  selector: 'app-banner-component',
  templateUrl: './banner-component.component.html',
  standalone: true,
  imports: [
    NgStyle
  ],
  styleUrl: './banner-component.component.css'
})
export class BannerComponentComponent implements OnInit{

  constructor() {
  }
  ngOnInit() {
  }

}
