<section id="doctors" class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-title text-center">
          <h1>Documents</h1>
          <h3>Download Forms</h3>
        </div>
      </div>
    </div>

    <div class="row clearfix" style="margin-left: 149px">
      <div class="col-lg-5 col-md-6 col-sm-12 news-block">
        <div class="news-block-one wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
          <div class="inner-box">
            <figure class="image-box">
              <div class="overlay-box-1"></div>
              <div class="overlay-box-2"></div>
              <img src="/assets/img/documents/LabSlip.jpeg" alt="">
              <i class="fas fa-link"></i>
            </figure>
            <div class="lower-content">
              <div class="upper-box">
                <h3>Lab Slip</h3>
                <a class="far fa-file-pdf" href="assets/documents/labslip.pdf" download="LabSlip"> Download</a>
                <!--                                    <p>We provide complete process piping capabilities for industrial from pulp and paper.</p>-->
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12 news-block">
        <div class="news-block-one wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
          <div class="inner-box">
            <figure class="image-box">
              <div class="overlay-box-1"></div>
              <div class="overlay-box-2"></div>
              <img src="/assets/img/documents/ColorChartForm.jpg" alt="">
              <i class="fas fa-link"></i>
            </figure>
            <div class="lower-content">
              <div class="upper-box">
                <!--                                    <ul class="post-info clearfix">-->
                <!--                                        <li><i class="fas fa-comments"></i><a href="blog-details.html">Comments 42</a></li>-->
                <!--                                        <li><i class="far fa-calendar-alt"></i>Jan 14, 2020</li>-->
                <!--                                    </ul>-->
                <h3>Color Chart Form</h3>
                <a class="far fa-file-pdf" href="assets/documents/Colorchart.pdf" download="ColorChart"> Download</a>
              </div>
              <!--                                <div class="lower-box clearfix">-->
              <!--                                    <div class="link"><a href="blog-details.html">READ MORE</a></div>-->
              <!--                                    <div class="admin"><i class="fas fa-user"></i><a href="blog-details.html">By Admin</a></div>-->
              <!--                                </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12 news-block">
        <div class="news-block-one wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
          <div class="inner-box">
            <figure class="image-box">
              <div class="overlay-box-1"></div>
              <div class="overlay-box-2"></div>
              <img src="/assets/img/documents/Orthodontic Screws.jpg" alt="">
              <i class="fas fa-link"></i>
            </figure>
            <div class="lower-content">
              <div class="upper-box">
                <h3>Orthodontic Screws</h3>
                <a class="far fa-file-pdf" href="assets/documents/screw.pdf" download="OrthodonticScrews"> Download</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-md-6 col-sm-12 news-block">
        <div class="news-block-one wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
          <div class="inner-box">
            <figure class="image-box">
              <div class="overlay-box-1"></div>
              <div class="overlay-box-2"></div>
              <img src="/assets/img/documents/iTero Thumbnail.jpeg" alt="">
              <i class="fas fa-link"></i>
            </figure>
            <div class="lower-content">
              <div class="upper-box">
                <h3>iTero Startup Guide</h3>
                <a class="far fa-file-pdf" href="assets/documents/iTero.pdf" download="iTero"> Download</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
