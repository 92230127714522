
<app-navigation-component></app-navigation-component>
<!-- Banner -->
<app-banner-component></app-banner-component>
<!--End Banner -->
<!-- About Us -->
<app-about></app-about>
<!-- End About Us -->

<!-- Services -->
<app-services></app-services>
<!-- End Services -->

<!-- Fun Facts -->
<app-fun-facts></app-fun-facts>
<!-- End Fun Facts -->

<!-- Portfolio -->
<app-portfolio></app-portfolio>
<!-- End Portfolio -->

<!-- Testimonials -->
<app-testimonials></app-testimonials>
<!-- End Testimonials -->

<!-- Technology -->
<app-technology></app-technology>
<!-- End Blog -->

<!-- Doctors -->
<app-documents></app-documents>
<!-- End Doctors -->

<!-- Contact Us -->
<app-contact-us></app-contact-us>


<!-- End Contact Us -->
<app-footer></app-footer>

<!-- Modal -->
<app-modal></app-modal>
<router-outlet />
